body {
  margin: 0;
  font-family: -apple-system,
   BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body .bg-light{
  background-color: #FEAE0F !important;
}

body{
  color: #1D1D1B;
}

@media only screen and (max-width: 991px) {
  body {
    background-color: lightblue;
  }

  .bg-footer{
    border-radius: 0px !important;
    padding: 10px !important;
  }
}

.ml-auto{
  margin-left: auto!important;
}

@media only screen and (max-width: 991px) {
  .navbar-custom .logo img{
    content: url(./assets/images/logo.png);
  }
}
.nav-sticky.navbar-custom .logo img{
  content: url(./assets/images/logo.png);
}

#navbar.navbar-custom .navbar-nav li a{
  color: #252c2c;
}

#navbar.navbar-custom .navbar-nav li a.active,
#navbar.navbar-custom .navbar-nav li a:hover,
#navbar.navbar-custom .navbar-nav li a:active{
  text-decoration: underline;
}

body .text-muted{
  color: #204663 !important;
}

body .navbar-custom .navbar-nav li a.active, 
body .navbar-custom .navbar-nav li a:hover, 
body .navbar-custom .navbar-nav li a:active{
  color: #3600b4 !important;
}

body .footer-link a{
  color: #3600b4;
  text-decoration: underline !important;
}

.section{
  padding-top: 50px;
  padding-bottom: 50px;
}

#contact{
  padding-top: 0;
}

#services{
  padding-bottom: 0;
}