.button{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
 
	box-shadow: 2px 2px 3px #999;
  z-index:100;

	animation: bounce2 2s ease infinite;
}

.button:hover {
	animation-play-state: paused;
	color: #fff;
}

.icon{
	font-size:38px;
	margin-top:2px;
	display: inline-block;
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}